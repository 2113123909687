import { Link } from '@reach/router';
import React from 'react';
import styled from 'styled-components';
import { Routes } from '../../common/constants';
import { StyledInformationalPage } from '../../common/static.styles';
import { HeadTitle } from '../../components/head-title/HeadTitle';
import { DefaultLayout } from '../../layout/default/DefaultLayout';

const StyledLanding = styled(StyledInformationalPage)`
  text-align: center;
`;

// noinspection JSUnusedGlobalSymbols
export default () => (
  <DefaultLayout locale='en-US'>
    <HeadTitle title='Landing' />
    <StyledLanding>
      <div className='content'>
        <h1>This web site is currently not available</h1>
        <p>Please check us out at these other Warner Bros. web sites:</p>
        <ul>
          <li>
            <Link to='/'>Warner Bros.</Link>
          </li>
          <li>
            <Link to={Routes.international}>Warner Bros. International</Link>
          </li>
          <li>
            <a href='https://www.harrypotter.com/'>Harry Potter</a>
          </li>
        </ul>
        <p>For our younger visitors, please check out:</p>
        <ul>
          <li>
            <a href='https://www.wbkidsgo.com'>Warner Bros. Kids</a>
          </li>
        </ul>
      </div>
    </StyledLanding>
  </DefaultLayout>
);
