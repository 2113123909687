import rem from 'polished/lib/helpers/rem';
import styled from 'styled-components';
import { mediaQueryWidth } from './breakpoints';
import { lightBlue } from './colors';

export const StyledInformationalPage = styled.div`
  div.content {
    background-color: #ffffff;
    padding: 1.25rem;
    padding-top: 1.5rem !important;

    ${mediaQueryWidth({ min: 'tablet' })`
      padding-left: ${rem(44)};
      padding-right: ${rem(44)};
    `}

    .info-page-logo {
      display: table;
      margin: auto;

      ${mediaQueryWidth({ min: 'tablet' })`
        float: left;
        width: auto;
      `}
    }

    a {
      color: ${lightBlue};
    }

    strong {
      font-weight: 800;
    }

    sup {
      line-height: 0;
    }
  }
`;
